@use "sass:map";

@use "@common/content/theme/font";
@use "@common/content/theme/font/face";

// Declare all @font-faces so swapping is available.
@each $_key,$_val in face.$vars {
    @font-face {
        font-family: $_val;
        src: url(#{"~./assets/" + $_val + ".woff2"}) format("woff2");
        @include font.set-face-props();
    }
}
