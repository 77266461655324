@use "sass:map";
@use "@platform/react-storybook/Component";
@use "@common/content/theme/box";

$name: Box;
$type: Component.$type-element;
$selector: Component.selector($name, $type);

$name-asset: #{"Asset" + $name};
$type-asset: Component.$type-section;
$selector-asset: Component.selector($name-asset, $type-asset);

$name-cover: #{"Cover" + $name};
$type-cover: Component.$type-section;
$selector-cover: Component.selector($name-cover, $type-cover);

$name-content: #{"Content" + $name};
$type-content: Component.$type-section;
$selector-content: Component.selector($name-content, $type-content);

$name-disabled: #{"Disabled" + $name};
$type-disabled: Component.$type-section;
$selector-disabled: Component.selector($name-disabled, $type-disabled);

@mixin set-fill() {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

@mixin set-asset($repeat: null, $size: null, $pos: null) {
    #{$selector-asset} {
        @include box.set($asset-repeat: $repeat, $asset-size: $size, $asset-pos: null);
        @content;
    }
}

:export {
    NAME_BOX: $name;
    NAME_ASSET_BOX: $name-asset;
    NAME_COVER_BOX: $name-cover;
    NAME_CONTENT_BOX: $name-content;
    NAME_DISABLED_BOX: $name-disabled;
    TYPE_BOX: $type;
    TYPE_ASSET_BOX: $type-asset;
    TYPE_COVER_BOX: $type-cover;
    TYPE_CONTENT_BOX: $type-content;
    TYPE_DISABLED_BOX: $type-disabled;
}
