@use "sass:map";
@use "sass:math";
@use "@common/content/theme/box";
@use "../Asset/Icon";
@use "." as this;

#{this.$selector} {
    position: relative;
    overflow: hidden;
    @each $p in map.keys(box.$presets) {
        &.#{$p} {
            @include box.set($preset: $p);
        }
    }
}

#{this.$selector-disabled} {
    display: block;
    z-index: 9;
    cursor: default;
    @include box.set($bg-color: brazil);
    @include this.set-fill();
}

#{this.$selector-cover} {
    display: block;
    z-index: 9;
    cursor: default;
    @include box.set($bg-color: nicaragua);
    @include this.set-fill();

    #{Icon.$selector} {
        $w: 3em;
        position: absolute;
        top: 50%;
        left: 50%;
        width: $w;
        // min-width: $w -1;
        // max-width: $w +1;
        margin-left: math.div($w, 2) * -1;
        margin-top: math.div($w, 2) * -1;
    }
}

#{this.$selector-content} {
    z-index: 1;
    position: relative;
}

#{this.$selector-asset} {
    z-index: 0;
    @include this.set-fill();
    // either a vector, a bitmap, or none.
    background-image: var(--vector, var(--bitmap, none));
}
