@use "sass:map";
@use "@platform/react-storybook/Component";
@use "@common/content/theme/box";
@use "@common/content/theme/box/border";
@use "@common/content/theme/font";
@use "@common/content/theme/color";

@use "../Text";
@use "../Asset/Icon";

$name: Button;
$type: Component.$type-element;
$selector: Component.selector($name, $type);

$presets: (
    nigeria: (
        normal: (
            box: pikachu,
            font: skadi,
            border-color: argentina,
            font-color: cuba,
        ),
        disabled: (
            box: pinsir,
            font: skadi,
            border-color: venezuela,
            font-color: venezuela,
        ),
        active: (
            box: bulbasaur,
            font: skadi,
            border-color: argentina,
            font-color: argentina,
        ),
    ),
    egypt: (
        normal: (
            box: zubat,
            font: skadi,
            border-color: chile,
            font-color: cuba,
            fill-color: cuba,
        ),
        disabled: (
            box: ponyta,
            font: skadi,
            font-color: cuba,
            fill-color: cuba,
        ),
        active: (
            box: bulbasaur,
            font: skadi,
            border-color: chile,
            font-color: chile,
            fill-color: chile,
        ),
    ),
    kenya: (
        normal: (
            box: ekans,
            font: skadi,
            border-color: guatemala,
            font-color: cuba,
        ),
        disabled: (
            box: pinsir,
            font: skadi,
            border-color: uruguay,
            font-color: uruguay,
        ),
        active: (
            box: bulbasaur,
            font: skadi,
            border-color: guatemala,
            font-color: guatemala,
        ),
    ),
    sudan: (
        normal: (
            box: tangela,
            font: skadi,
            border-color: mexico,
            font-color: cuba,
        ),
        disabled: (
            box: pinsir,
            font: skadi,
            border-color: guyana,
            font-color: guyana,
        ),
        active: (
            box: bulbasaur,
            font: skadi,
            border-color: mexico,
            font-color: mexico,
        ),
    ),
    morocco: (
        normal: (
            box: pikachu,
            font: skadi,
            border-color: argentina,
            font-color: cuba,
        ),
        disabled: (
            box: ponyta,
            font: skadi,
            font-color: cuba,
        ),
        active: (
            box: bulbasaur,
            font: skadi,
            border-color: argentina,
            font-color: argentina,
        ),
    ),
    angola: (
        normal: (
            box: pikachu,
            font: skadi,
            border-color: argentina,
            font-color: cuba,
            fill-color: cuba,
        ),
        disabled: (
            box: ponyta,
            font: skadi,
            font-color: cuba,
            fill-color: cuba,
        ),
        active: (
            box: bulbasaur,
            font: skadi,
            border-color: argentina,
            font-color: argentina,
            fill-color: argentina,
        ),
    ),
    ghana: (
        normal: (
            box: paras,
            font: tarvos,
            border-color: chile,
            font-color: chile,
            fill-color: chile,
        ),
        disabled: (
            box: paras,
            font: tarvos,
            border-color: bolivia,
            font-color: bolivia,
            fill-color: bolivia,
        ),
        active: (
            box: geodude,
            font: tarvos,
            border-color: argentina,
            font-color: cuba,
            fill-color: cuba,
        ),
    ),
    namibia: (
        normal: (
            box: paras,
            border-color: chile,
            fill-color: chile,
        ),
        disabled: (
            box: paras,
            border-color: bolivia,
            fill-color: bolivia,
        ),
        active: (
            box: geodude,
            border-color: argentina,
            fill-color: cuba,
        ),
    ),
    mali: (
        normal: (
            box: paras,
            font: tarvos,
            border-color: chile,
            font-color: chile,
        ),
        disabled: (
            box: paras,
            font: tarvos,
            border-color: bolivia,
            font-color: bolivia,
        ),
        active: (
            box: geodude,
            font: tarvos,
            font-color: cuba,
            border-color: argentina,
        ),
    ),
    gabon: (
        normal: (
            box: none,
            font: default,
            font-color: chile,
            font-line-color: chile,
            font-line-size: banana,
            font-transform: ribeye,
        ),
        disabled: (
            box: none,
            font: default,
            font-color: bolivia,
            font-line-color: bolivia,
            font-line-size: banana,
            font-transform: ribeye,
        ),
        active: (
            box: none,
            font: default,
            font-color: cuba,
            font-line-color: cuba,
            font-line-size: banana,
            font-transform: ribeye,
        ),
    ),
    camerun: (
        normal: (
            box: none,
            font: callao,
            font-color: chile,
            font-transform: ribeye,
        ),
        disabled: (
            box: none,
            font: callao,
            font-color: bolivia,
            font-transform: ribeye,
        ),
        active: (
            box: none,
            font: callao,
            font-color: chile,
            font-transform: ribeye,
        ),
    ),
    somalia: (
        normal: (
            box: none,
            font: fenrir,
            font-color: chile,
            font-transform: ribeye,
        ),
        disabled: (
            box: none,
            font: fenrir,
            font-color: bolivia,
            font-transform: ribeye,
        ),
        active: (
            box: none,
            font: fenrir,
            font-color: chile,
            font-transform: ribeye,
        ),
    ),
    malawi: (
        normal: (
            box: none,
            fill-color: argentina,
        ),
        disabled: (
            box: none,
            fill-color: bolivia,
        ),
        active: (
            box: none,
            fill-color: venezuela,
        ),
    ),
    zambia: (
        normal: (
            box: none,
            fill-color: chile,
        ),
        disabled: (
            box: none,
            fill-color: bolivia,
        ),
        active: (
            box: none,
            fill-color: argentina,
        ),
    ),
    chad: (
        normal: (
            box: none,
            fill-color: panama,
        ),
        disabled: (
            box: none,
            fill-color: bolivia,
        ),
        active: (
            box: none,
            fill-color: chile,
        ),
    ),
    benin: (
        normal: (
            box: none,
            fill-color: bolivia,
        ),
        disabled: (
            box: none,
            fill-color: bolivia,
        ),
        active: (
            box: none,
            fill-color: chile,
        ),
    ),
    togo: (
        normal: (
            box: none,
            fill-color: chile,
        ),
        disabled: (
            box: none,
            fill-color: bolivia,
        ),
        active: (
            box: mankey,
            fill-color: cuba,
        ),
    ),
);

@mixin set($preset-name) {
    $preset: map.get($presets, $preset-name);

    @if not $preset {
        @error "Invalid preset: " + $preset-name + ".";
    }

    @each $preset-type, $preset-vals in $preset {
        $selector: "&";
        @if $preset-type == disabled {
            $selector: "&[disabled]";
        }
        @if $preset-type == active {
            $selector: "&:active:not([disabled])";
        }

        $box: map.get($preset-vals, box);
        $font: map.get($preset-vals, font);
        $font-color: map.get($preset-vals, font-color);
        $font-line-color: map.get($preset-vals, font-line-color);
        $font-line-size: map.get($preset-vals, font-line-size);
        $font-transform: map.get($preset-vals, font-transform);
        $border-color: map.get($preset-vals, border-color);
        $fill-color: map.get($preset-vals, fill-color);
        $stroke-color: map.get($preset-vals, stroke-color);

        #{$selector} {
            @if $box {
                @include box.set($preset: $box);
            }
            @if $border-color {
                @include border.set($color: $border-color);
            }
            @if $font {
                @include font.set(
                    $preset: $font,
                    $color: $font-color,
                    $line-color: $font-line-color,
                    $line-size: $font-line-size,
                    $transform: $font-transform
                );
            }
            @if $fill-color {
                #{Icon.$selector} {
                    @include color.set($svg-fill: $fill-color);
                }
            }
            @if $stroke-color {
                #{Icon.$selector} {
                    @include color.set($svg-stroke: $stroke-color);
                }
            }
        }
    }
}

:export {
    NAME_BUTTON: $name;
    TYPE_BUTTON: $type;
}
