@use "sass:map";
@use "sass:list";
@use "sass:meta";
@use "@platform/styles/util";

@use "../../Component";
@use "./util/props";
@use "./util/args";
@use "./util/presets";
@use "./util/extras";

$name: ThemePlatform;
$type: Component.$type-bundle;
$selector: Component.selector($name, $type);

$name-consumer: #{$name + "Consumer"};
$type-consumer: Component.$type-element;
$selector-consumer: Component.selector($name-consumer, $type-consumer);

$name-provider: #{$name + "Provider"};
$type-provider: Component.$type-provider;
$selector-provider: Component.selector($name-provider, $type-provider);

// ------------------------------------------------------------------------------------------- FUNCS

// Helper function that shortcuts the process of obtaining vars from the theme.
@function vars-get($vars, $name) {
    @if not util.is-map($vars) {
        @error util.throwable($name: "vars", $type: "map", $value: $vars);
    }
    $vars: map.get($vars, $name);
    @if not $vars {
        @error util.throwable($name: "var", $type: $vars, $value: $name);
    }
    @return $vars;
}

// Returns a schema ready to be used by the Theme.render mixin
// - $args:
//   The arguments sent by the user so we can know that will be rendered.
//   A map of prop-aliases and their respective value, which can override a preset.
//   ie: (prop-alias<string>: prop-value-alias<string>, ...)
// - $preset
//   A string describing the theme that has to be applied.
// - $props:
//   A map containing all the info about specidied prop like:
//   (prop-alias<string>: prop-tuple)
//   where prop-tuple is:
//   ie: (prop-name<string>, prop-possible-values<( prop-value-alias<string>: prop-value<string> )>)
// - $extras
//   A list of prop-alias that should not be rendered right away, but returned to be parsed later.
//   ie: (<string>, <string>, ...)
// - $presets
// - A map of presets to be used as shortcuts for writing props.
//   ie: (preset-name<string>: (prop-alias<string>: prop-value-alias<string>, ...), ...)
@function data-parse($args:(), $preset: null, $props: (), $presets: (), $extras: null) {
    // simple validation for extras
    $is-extras-string: meta.type-of($extras) == "string";
    $is-extras-list: list.length($extras) > 0 and meta.type-of($extras) == "list";
    @if $extras and not $is-extras-string and not $is-extras-list {
        @error util.throwable($name: "extras", $type: "list", $value:$extras);
    }
    $props: props.parse($props);
    $args: args.parse($args, $extras);
    $presets: presets.parse($presets, $extras);
    @if $extras {
        $extras: extras.parse(
            $preset,
            map.get($args, extras),
            map.get($presets, extras)
        );
    }
    @return (
        schema: (
            preset: $preset,
            props: $props,
            args: map.get($args, schema),
            presets: map.get($presets, schema),
        ),
        extras: $extras,
    );
}


// -------------------------------------------------------------------------------------------- MODS

@function mod-negative($value) {
    @return calc(#{$value} * -1);
}

// ------------------------------------------------------------------------------------------ MIXINS

// Given a schema, render rules.
@mixin render($schema, $mods: null) {
    $schema-ref: (preset, props, args, presets);
    $schema-key: map.keys($schema);
    // do a simple checkup for schema
    @if $schema-key != $schema-ref {
        @error util.throwable($name: "schema", $type: $schema-ref, $value: $schema-key);
    }
    $args: map.get($schema, args);
    // if a valid preset is provided, then use the preset as base and let args extend it.
    // if the preset is empty or invalid, fail silently because it might be an "extras" preset.
    $preset: map.get($schema, preset);
    @if $preset {
        $presets: map.get($schema, presets);
        $current: map.get($presets, $preset);
        @if $current {
            $args: map.merge($current, $args);
        }
    }
    // iterate argumens and resolve aliases to their corresponding prop:value
    $props: map.get($schema, props);
    $props-aliases: map.get($props, aliases);
    $props-values: map.get($props, values);
    @each $arg-key, $arg-val in $args {
        $prop-key: map.get($props-aliases, $arg-key);
        $values: map.get($props-values, $arg-key);
        @if not $values {
            @error "Argument \"" + $arg-key+ "\", is an invalid prop.";
        }
        // as a hidden feature, instead of complaining when an alias is not found,
        // use the raw value instead, that way arbitrary css-values can be used.
        // NOTE: this won't be publicly disclosed to disencourage devs to use raw-values
        //       and use aliases instead.
        $prop-val: map.get($values, $arg-val);
        @if not $prop-val and $arg-val {
            $prop-val: $arg-val;
        }
        @if $prop-val and $prop-key {
            // if a mod (modifier) is sent, execute it upone current value.
            @if $mods {
                @each $mod-suffix in $mods {
                    $mod-name: "mod-#{$mod-suffix}";
                    @if not meta.function-exists($mod-name) {
                        @error "Invalid mod \"#{$mod-suffix}\".";
                    }
                    $mod: meta.get-function($mod-name);
                    $prop-val: meta.call($mod, $prop-val);
                }
            }
            #{$prop-key}: $prop-val;
        }
    }
}

:export {
    NAME_THEMEPLATFORM: $name;
    TYPE_THEMEPLATFORM: $type;
    NAME_CONSUMER_THEMEPLATFORM: $name-consumer;
    TYPE_CONSUMER_THEMEPLATFORM: $type-consumer;
    NAME_PROVIDER_THEMEPLATFORM: $name-provider;
    TYPE_PROVIDER_THEMEPLATFORM: $type-provider;
}
