@use "." as this;
@use "@common/content/theme/box/flex";
@use "@common/content/theme/box";
@use "@front/components/Element/Box";
@use "@front/components/Element/Text";
@use "@front/components/Element/Button";
@use "@common/content/theme/color";

#{this.$selector} {
    @include flex.set($dir: column, $justify: center, $align: center);
    @include box.set($bg-color: peru);
    background: linear-gradient(to bottom, color.get(cuba) 2%, color.get(honduras) 100vh);

    height: 100vh;
    #{Text.$selector} {
        @include box.set($margin-t: grape);
        text-align: center;
    }

    & > div {
        #{Button.$selector} {
            @include box.set($margin: cherry);
        }
    }
}
