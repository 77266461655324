@use "sass:meta";
@use "sass:map";
@use "sass:list";
@use "sass:math";

@use "../../color";
@use "./type";

// only used to validate props
$props: (type, steps);

$presets: (
    action: (
        type: ttb,
        steps: (
            (none, 0),
            (cuba, 1),
        ),
    ),
    comedy: (
        type: ttb,
        steps: (
            (colombia, 0),
            (argentina, 1),
        ),
    ),
    crime: (
        type: ttb,
        steps: (
            (guatemala, 0),
            (argentina, 1),
        ),
    ),
    drama: (
        type: ttb,
        steps: (
            (colombia, 0),
            (mexico, 1),
        ),
    ),
    fantasy: (
        type: ttb,
        steps: (
            (colombia, 0),
            (ecuador, 1),
        ),
    ),
    horror: (
        type: ttb,
        steps: (
            (guatemala, 0),
            (ecuador, 1),
        ),
    ),
    musical: (
        type: ttb,
        steps: (
            (argentina, 0),
            (mexico, 1),
        ),
    ),
    mistery: (
        type: ttb,
        steps: (
            (colombia, 0),
            (cuba, 1),
        ),
    ),
    romance: (
        type: ttb,
        steps: (
            (argentina, 0),
            (cuba, 1),
        ),
    ),
    thriller: (
        type: ttb,
        steps: (
            (guatemala, 0),
            (cuba, 1),
        ),
    ),
    war: (
        type: ttb,
        steps: (
            (mexico, 0),
            (cuba, 1),
        ),
    ),
    western: (
        type: ttb,
        steps: (
            (ecuador, 0),
            (cuba, 1),
        ),
    ),
    documentary: (
        type: ttb,
        steps: (
            (bolivia, 0),
            (cuba, 1),
        ),
    ),
    satire: (
        type: ttb,
        steps: (
            (cuba, 0),
            (peru, 0.3),
        ),
    ),
    scifi: (
        type: ttb,
        steps: (
            (cuba, 0.02),
            (ecuador, 0.15),
            (ecuador, 0.4),
            (cuba, 0.5),
            (cuba, 0.8),
            (ecuador, 1),
        ),
    ),
);

@mixin set($preset: null, $args...) {
    // args should be a map like: (type:blah, steps: ((bleh, 0), (blih, 1)))
    $keywords: meta.keywords($args);
    @if $preset {
        $sets: map.get($presets, $preset);
        @if not $sets {
            @error "Invalid preset \"#{$preset}\", use: #{map.keys($presets)}";
        }
        $keywords: map.merge($sets, $keywords);
    }
    $_verb: "linear-gradient";
    $_type: null;
    $_steps: ();
    @each $k, $v in $keywords {
        @if not list.index($props, $k) {
            @error "Invalid argument \"#{$k}\", use: #{$props}";
        }
        // deal with types
        @if $k == "type" {
            // change the verb to radial if needed.
            @if $v == "rad" {
                $_verb: "radial-gradient";
            }
            $_type: map.get(type.$vars, $v);
            @if not $_type {
                @error "Invalid $type: \"#{$v}\", use: #{map.keys(type.$vars)}";
            }
        }
        @if $k == "steps" {
            @if not $v or meta.type-of($v) != "list" or list.length($v) < 1 {
                @error "$steps: Expecting a list, got: \"#{meta.inspect($v)}\"";
            }
            @each $step in $v {
                @if not $step or meta.type-of($step) != "list" or list.length($step) != 2 {
                    @error "$steps: each step should be (color, number), got: \"#{meta.inspect($step)}\"";
                }
                $color: color.get(list.nth($step, 1));
                $pos: math.percentage(list.nth($step, 2));
                $_steps: list.append($_steps, ($color $pos), $separator: comma);
            }
        }
    }
    background: #{$_verb }($_type, $_steps);
}
