@use "." as this;

#{this.$selector} {
    display: flex;
    padding: 0;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    border-width: 0;
    outline: none;

    &:hover {
        cursor: pointer;
    }

    &[disabled] {
        opacity: 0.8;

        &:hover {
            cursor: default;
        }
    }

}
