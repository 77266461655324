@use "sass:map";
@use "." as this;

#{this.$selector} {
    @each $p in map.keys(this.$presets) {
        &[data-preset="#{$p}"],
        [data-preset="#{$p}"]{
            @include this.set($p);
        }
    }
}
