@use "." as this;

@use "@platform/styles/screen";
@use "@platform/styles/size";

@use "@common/content/theme/box/space";
@use "@common/content/theme/color";

#{this.$selector} {
    & > figure {
        grid-area: HeadVideo;
        position: relative; // has to be set for z-index
        z-index: 0;
        overflow: hidden;
        height: 100%;
        width: 100%;

        & > div {
            content: "";
            display: block;
            width: space.get(mango);
            height: space.get(mango);
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: calc(-1 * space.get(mango) / 2);
            margin-top: calc(-1 * space.get(mango) / 2);
            background-color: color.get(argentina);
            border-radius: space.get(pear);
            box-shadow: 0 0 2em 0 rgba(0, 0, 0, 0.7);
            background-image: var(--bg);
            background-repeat: no-repeat;
            background-size: 70%;
            background-position-x: center;
            background-position-y: center;

            &.videoIconLoading {
                display: none;
                animation: rotating 2s linear infinite;

                @keyframes rotating {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
            }
        }

        &.videoLoading > div.videoIconLoading {
            display: block;
        }
    }

    &.isFullScreen {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &.hasBackground {
        & > figure {
            background-color: color.get(cuba);
        }
    }
}
