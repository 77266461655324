@use "sass:map";
@use "@common/content/theme/box";
@use "@common/content/theme/box/flex";

@use "../Asset/Icon";
@use "." as this;

#{this.$selector} {
    @include box.set($padding: pear);
    @include flex.set($justify: inner, $align: center);

    #{Icon.$selector} {
        width: 1em;
        line-height: 0.8em;
        margin-right: 0.33em;
    }

    @each $preset-name in map.keys(this.$presets) {
        &[data-preset="#{$preset-name}"] {
            @include this.set($preset-name);
        }
    }
}
