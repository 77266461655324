@use "sass:map";
@use "sass:meta";
@use "@platform/react-storybook/Bundle/Theme";

@use "../color";
@use "../box/space";

@use "./face";
@use "./size";
@use "./weight";
@use "./style";
@use "./align";
@use "./deco";
@use "./transform";
@use "./wrap-word";
@use "./wrap-space";

$props: (
    face: (
        font-family,
        face.$vars,
    ),
    size: (
        font-size,
        size.$vars,
    ),
    weight: (
        font-weight,
        weight.$vars,
    ),
    style: (
        font-style,
        style.$vars,
    ),
    color: (
        color,
        color.$vars,
    ),
    align: (
        text-align,
        align.$vars,
    ),
    height: (
        line-height,
        size.$vars,
    ),
    deco: (
        text-decoration,
        deco.$vars,
    ),
    transform: (
        text-transform,
        transform.$vars,
    ),
    wrap-word: (
        word-wrap,
        wrap-word.$vars,
    ),
    wrap-space: (
        white-space,
        wrap-space.$vars,
    ),
    line-size: (
        -webkit-text-stroke-width,
        space.$vars,
    ),
    line-color: (
        -webkit-text-stroke-color,
        color.$vars,
    ),
    fill-color: (
        -webkit-text-fill-color,
        color.$vars,
    ),
);

$presets: (
    default: (
        face: pansyl,
        size: celery,
        weight: boxer,
        height: corn,
    ),
    enceladus: (
        face: pansyl,
        size: tomato,
        weight: boxer,
        height: carrot,
    ),
    tarvos: (
        face: pansyl,
        size: carrot,
        weight: boxer,
        height: celery,
    ),
    febe: (
        face: pansyl,
        size: carrot,
        weight: collie,
        height: celery,
    ),
    mimas: (
        face: pansyl,
        size: celery,
        weight: collie,
        height: corn,
    ),
    amalthea: (
        face: tulip,
        size: celery,
        weight: bulldog,
        height: corn,
    ),
    janus: (
        face: pansyl,
        size: carrot,
        weight: schnauzer,
        height: celery,
    ),
    narvi: (
        face: lotus,
        size: pea,
        weight: schnauzer,
        height: pea,
    ),
    skadi: (
        face: lotus,
        size: carrot,
        weight: schnauzer,
        height: celery,
    ),
    lapetus: (
        face: tulip,
        size: spinach,
        weight: bulldog,
        height: onion,
    ),
    dione: (
        face: tulip,
        size: pepper,
        weight: bulldog,
        height: pepper,
    ),
    atlas: (
        face: tulip,
        size: corn,
        weight: bulldog,
        height: corn,
    ),
    titan: (
        face: tulip,
        size: radish,
        weight: bulldog,
        height: corn,
    ),
    fenrir: (
        face: tulip,
        size: carrot,
        weight: bulldog,
        height: celery,
    ),
    tethys: (
        face: lily,
        size: pepper,
        weight: beagle,
        height: pepper,
    ),
);

@mixin set($preset: null, $args...) {
    $data: Theme.data-parse(meta.keywords($args), $preset, $props, $presets);
    @include Theme.render(map.get($data, schema));
}

@mixin set-face-props {
    // swap font once downloaded
    font-display: swap;
    // latin
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
