@use "@platform/react-storybook/Bundle/Theme";

// Since setting a font-family depends upon setting a @font-family, and that is being set statically
// it doesn't make sense for these to be dynamic (vars obtained from ThemeData).
// if you want to add an additional font: determine a flower name, add it to the list as key name,
// and add the filename (without extension) as value.
$vars: (
    lotus: "akzidenzgrotesk-bold",
    tulip: "akzidenzgrotesk-medium",
    lily: "akzidenzgrotesk-light",
    pansyl: "inter",
);

@function get($name) {
    @return Theme.vars-get($vars, $name);
}
